// eslint-disable-next-line no-redeclare,no-unused-vars
function contextMenu(
  element,
  items,
  callback,
  beforeContext,
  event = 'contextmenu',
  prepend = false
) {
  if (!element || isEmptyObject(items)) return;
  if (mobile) event = 'taphold';

  element.each(function () {
    var el = this;
    var target = $(this);

    // add events to element
    if (target.hasClass('with-context')) target.off(event);
    if (prepend) el.items = Object.assign({}, items, el.items);
    else if (el.items) el.items = Object.assign({}, el.items, items);
    else el.items = Object.assign({}, items);
    if (el.callbacks) el.callbacks.push(callback);
    else el.callbacks = [callback];
    target.addClass('with-context');

    // add on mouseover
    var add = function () {
      // add parent events
      target.parents('.with-context').each(function (i, parent) {
        if (parent.items) {
          Object.assign(el.items, parent.items);
          el.callbacks = arrayMerge(el.callbacks, parent.callbacks);
        }
      });

      // adds sub events
      if (mobile)
        target.children('.with-context').each(function (i, child) {
          if (child.items) {
            Object.assign(el.items, child.items);
            el.callbacks = arrayMerge(el.callbacks, child.callbacks);
          }
        });

      // show context menu
      target.on(event, function (evt) {
        if (evt.altKey) return;
        $('#contextMenu').remove();

        var pos = {};
        if (mobile) pos = target.offset();
        else if (evt.pageX) {
          pos.left = evt.pageX;
          pos.top = evt.pageY;
        } else {
          pos.left = $(window).width() / 2;
          pos.top = $(window).height() / 2;
        }

        var menu = $('<div id="contextMenu"></div>')
          .appendTo(document.body)
          .css(pos);
        $.each(el.items, function (type, label) {
          if (Array.isArray(label)) {
            // sub
            var labelMenu = $(
              '<div class="contextSub">' + label[0] + ' ></div>'
            )
              .appendTo(menu)
              .hover(
                function () {
                  $(this).children('div').show();
                },
                function () {
                  $(this).children('div').hide();
                }
              );
            $.each(label[1], function (type, label) {
              $('<div>' + label + '</div>')
                .appendTo(labelMenu)
                .on(click, function () {
                  $.each(el.callbacks, function (i, callback) {
                    callback(type, target, label, evt);
                  });
                  menu.remove();
                });
            });
          } else
            $('<div>' + label + '</div>')
              .appendTo(menu)
              .on(click, function () {
                $.each(el.callbacks, function (i, callback) {
                  var result = callback(type, target, label, evt);
                  if (result === false) return false;
                });
                menu.remove();
              }); // normal
        });
        var left = menu.offset().left;
        var top = menu.offset().top;
        var width = menu.outerWidth();
        var height = menu.outerHeight();
        if (beforeContext) beforeContext();

        $(document).one(click, function () {
          $('#contextMenu').remove();
        });
        if (left + width > $(window).width()) menu.css('left', left - width);
        if (top > height && top + height > $(window).height())
          menu.css('top', top - height);
        return false;

        // // mobile
        // else {
        // 	var clicks = [];
        // 	menu.css('top', top - height - 25);
        // 	if (events && events.click)
        // 		$.each(events.click, function (i, cl) {
        // 			clicks.push(cl.handler);
        // 			target.off(click, cl.handler);
        // 		});
        // 	target.one('touchend', function () {
        // 		setTimeout(function () {
        // 			$(document.body)
        // 				.addClass('link')
        // 				.one(click, function () {
        // 					$('#contextMenu').remove();
        // 					$.each(clicks, function (i, handler) {
        // 						target.on(click, handler);
        // 					});
        // 					$(document.body).removeClass('link');
        // 				});
        // 		}, 100);
        // 	});
      });
    };

    if (mobile) add();
    else target.one('mouseover', add);
  });
}
